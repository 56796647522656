import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";

var isGallery = false;

const GET_CATEGORIES = gql`
  query Categories($isGallery: Boolean!) {
    getCategories(isGallery: $isGallery) {
      name
      id
      alternativeName
      slug
      isGallery
    }
  }
`;

class CategoryList extends Component {
  render() {
    let language = this.props.language;

    isGallery = this.props.gallery;

    if (!language) {
      language = window.location.href.toString().split("/")[3];
      if (language === "") {
        language = "english";
      }
    }

    return (
      <Query
        asyncMode
        query={GET_CATEGORIES}
        variables={{ isGallery: isGallery }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return ( false );
          if (error) return false;

          if (language === "telugu") {
            return (
              <>
                {data.getCategories.map((category, index) => (
                  <li className="nav-link vs-hide" key={index}>
                    <a href={`/${language}` + "/category/" + category.slug}>
                      {category.alternativeName}
                    </a>
                  </li>
                ))}
              </>
            );
          }

          return (
            <>
              {data.getCategories.map((category, index) => (
                <li className="nav-link vs-hide" key={index}>
                  <a href={`/${language}` + "/category/" + category.slug}>
                    {category.name}
                  </a>
                </li>
              ))}
            </>
          );
        }}
      </Query>
    );
  }
}

export default CategoryList;
