import React,  { Component } from 'react';

export default class AdComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      client: "ca-pub-9159861940131797",
      slot: this.props.slot,
      format: this.props.format
    };
  }

  componentDidMount () {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

render () {
    return (
        <ins className='adsbygoogle'
          style={{ display: 'block',width:'100%', height:'90px' }}
          data-ad-client={this.state.client}
          data-ad-slot={this.state.slot}
          data-full-width-responsive="true" />
    );
  }
}