export function slug(data)
{
    return data
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        ;
}


export function capitalize(s)
{	s.toLowerCase();
    return s && s[0].toUpperCase() + s.slice(1);
}