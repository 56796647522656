import React, { Component } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Logo";
import { DashLogo } from "../../Logo";
import moment from "moment";
import BreakingNews from "../../Home/BreakingNews";
import CategoryList from "../../Home/Category/getCategories";
import strings from "../../Locale";
import AdComponent from "../../Ads";

var isSide = false;
var language;



class SiteHeader extends Component {
  constructor(props) {
    super(props);

    language = window.location.href.toString().split("/")[3];
    if (language === "") {
      language = "english";
    }

    strings.setLanguage(language.toString());

    this.state = {
      search: "",
      language: language,
      searchActive: false,
      menuActive: false,
    };

    this.submitSearchForm = this.submitSearchForm.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {}

  submitSearchForm = (event) => {
    event.preventDefault();

    let url = "/" + language + "/search/" + this.state.search;
    window.location = url;
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  openSearchBar = () => {
    this.setState({ searchActive: true });
  };

  closeSearchBar = () => {
    this.setState({ searchActive: false });
  };

  toggleMenu = () => {
    this.setState({ menuActive: !this.state.menuActive });
  };

  render() {
    return (
      <header>
        <div
          className={`header-wrapper ${
            this.state.searchActive ? `search-active` : ``
          }`}
        >
          <div className="logo-wrapper">
            <div className="image-wrapper">
              <Link to={`/${language}`}>
                <Logo />
                Homepage
              </Link>
            </div>
            <div className="date-wrapper">
              <div className="date-info">{moment().format("dddd, LL")}</div>
            </div>
          </div>
          <div class="ad-unit-wrapper">
            <AdComponent  slot="3088728076" format="horizontal" />
          </div>
          <div
            className={`nav-wrapper ${
              this.state.menuActive ? `menu-active` : ``
            }`}
          >
            <div
              className={`burger ${this.state.menuActive ? `active` : ``}`}
              onClick={this.toggleMenu}
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
            <nav className="primary-nav">
              <ul className="left-links">
                <li className="nav-item home">
                  <Link
                    to={`/${language}`}
                    className="nav-link"
                  >
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/${language}/category/movies`}
                    className="nav-link"
                  >
                    {strings.movies}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`/${language}` + "/politics"} className="nav-link">
                    {strings.politics}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to={`/${language}` + "/gallery"} className="nav-link">
                    {strings.gallery}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to={`/${language}` + "/movie-reviews"}
                    className="nav-link"
                  >
                    {strings.moviereviews}
                  </Link>
                </li>
                <li className="dropdown nav-item cat-drop">
                  <a
                    href="#"
                    className="dropdown-toggle nav-link"
                    data-toggle="dropdown"
                  >
                    {strings.Others}
                    <span className="caret"></span>
                  </a>
                  <ul className="dropdown-menu" id="drop-nav">
                    <CategoryList gallery={false} language={language} />
                  </ul>
                </li>
                <li className="seperator vs-hide">Other Categories</li>
                <CategoryList gallery={false} />
              </ul>
              <ul className="right-links">
                <li>
                  {language == "" || language == "telugu" ? (
                    <a href="/english">English</a>
                  ) : (
                    <a href="/telugu">తెలుగు</a>
                  )}
                </li>
                
                <li>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/TotalTeluguNews"
                  >
                    <i className="fab fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://twitter.com/TotalTelugu">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li className="search-wrapper">
                  <span className="search-trigger" onClick={this.openSearchBar}>
                    <i className="fa fa-search" aria-hidden="true"></i>
                  </span>
                </li>
                <li className="nav-item home-mobile">
                  <Link
                    to='/'
                    className="nav-link"
                  >
                    <i className="fa fa-home" aria-hidden="true"></i>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="search-container">
            <div className="search-wrapper">
              <form onSubmit={this.submitSearchForm} className="search-form">
                <input
                  type="text"
                  name="search"
                  value={this.state.search}
                  className="m-search-input"
                  onChange={this.handleSearchChange}
                  placeholder="Search articles"
                  required
                />
                <input type="submit" value="Search" />
                <div className="search-close" onClick={this.closeSearchBar}>
                  Close
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="flash-wrapper">
          <div className="flash-header">
              <h2>{strings.breakingnews}</h2>
          </div>
          <div className="flash-container">
            <BreakingNews language={language} />
          </div>
        </div>
      </header>
    );
  }
}

export default SiteHeader;
