import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import Sidebar from "../../Sidebar";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import Rating from "react-rating-doped";
import { FBIcon } from "../../Article/icons";
import { TWIcon } from "../../Article/icons";
import MetaTags from "react-meta-tags";
import strings from "../../Locale";
import ThumbnailNews from "../../Home/ThumbnailNews";
import Image from "react-graceful-image";

var keywordsArr = [];

const GET_REVIEW = gql`
  query GetArticle($slug: String, $language: String) {
    getArticles(slug: $slug, language: $language) {
      article {
        id
        slug
      }
      title
      featuredImage
      description
      metaDescription
      movieSet {
        cast
        director
        producer
        certification
        duration
        releaseDate
        musicDirector
        rating
        review
        language
      }

      category {
        id
        slug
        name
      }

      subcategory {
        name
        slug
      }

      articlekeywordsSet {
        keywords {
          keyword
        }
      }
    }
  }
`;
var language;
class SingleMovieReview extends Component {
  render() {
    var slug = this.props.match.params.slug;
    language = this.props.match.params.telugu;

    return (
      <Query query={GET_REVIEW} variables={{ slug: slug, language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error Loading Data</div>;
          return <MovieContent content={data.getArticles} />;
        }}
      </Query>
    );
  }
}

export const MovieContent = (data) => {
  return (
    <div className="body-wrapper">
      <SiteHeader />
      <MetaTags>
        <title>{data.content[0].title} | TotalTelugu</title>
        <meta name="description" content={data.content[0].metaDescription} />
        <meta property="og:type" content="article" />

        <meta property="og:title" content={data.content[0].title} />

        <meta
          property="og:description"
          content={data.content[0].metaDescription}
        />

        <meta property="og:image" content={data.content[0].featuredImage} />

        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="og:site_name" content="TotalTelugu" />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>
      <div class="article-wrapper">
        <div class="item-headers">
          <div class="item-title">
            <h1>{data.content[0].title}</h1>
          </div>
        </div>
        <div class="item-content">
          <div class="item-misc-info">
            <div class="item-category">
              <span class="item-category">
                <Link to={`/${language}/${data.content[0].category.slug}`}>
                  {data.content[0].category.name}{" "}
                </Link>
              </span>
              <span class="item-subcategory">
                <Link to={`/${language}/${data.content[0].subcategory.slug}`}>
                  {" "}
                  / {data.content[0].subcategory.name}{" "}
                </Link>
              </span>
            </div>
            <div class="item-date">
              {" "}
              - {moment(data.content[0].article.modifiedAt).format("dddd, LL")}
            </div>
            <div class="item-share">
              <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                target="_blank"
                href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?text=${data.content[0].title}  ${window.location.href}`}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="item-featured-iamge">
          <Image
            src={data.content[0].featuredImage}
            alt={data.content[0].title}
          />
        </div>
        <div class="item-body-wrapper">
          <div class="review-info">
            <div class="rating-wrapper --mt15">
              <span className="label">Rating: </span>
              <span className="item-rating">
                <Rating
                  rating={data.content[0].movieSet[0].rating}
                  displayOnly={true}
                />
              </span>
            </div>
            <div class="review-genre --mt15">
              <ul className="genre-list">
                {data.content[0].articlekeywordsSet.map((tags, index) => (
                  <li key={index}>
                    <a href={`/${language}` + "/tag/" + tags.keywords.keyword}>
                      {tags.keywords.keyword}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div class="review-certification --mt15">
              <span>Certification: {data.content[0].movieSet[0].certification} </span>
              <span>| Language: {data.content[0].movieSet[0].language}</span>
            </div>
            <div class="review-date --mt15">
              <span>
                Release date: {moment(data.content[0].movieSet[0].releaseDate).format("LL")}{" "}
              </span>
            </div>
            <div class="review-time --mt15">
              <span> Runtime: {data.content[0].movieSet[0].duration}</span>
            </div>
            <div class="review-cast --mt15">
              <span>Cast: {data.content[0].movieSet[0].cast}</span>
            </div>
            <div class="review-driector --mt15">
              <span>Director: {data.content[0].movieSet[0].director}</span>
            </div>
            <div class="review-producer --mt15">
              <span>Producer: {data.content[0].movieSet[0].producer}</span>
            </div>
            <div class="review-music --mt15">
              <span>
                Music Director: {data.content[0].movieSet[0].musicDirector}
              </span>
            </div>
          </div>
          <div class="review-body">
            <p
              dangerouslySetInnerHTML={{
                __html: data.content[0].description,
              }}
            />
          </div>
        </div>
        <div class="review-main">
          <span className="label">Review: </span>
          <p
            dangerouslySetInnerHTML={{
              __html: data.content[0].movieSet[0].review,
            }}
          />
        </div>
        <div class="item-tags"></div>
        <div class="item-extras">
          <div class="item-related --mt">
            <div class="item-header">
              <h3>Related Reviews</h3>
            </div>
            <div class="item-container">
              <ThumbnailNews
                title={strings.relatedarticles}
                keywords={keywordsArr}
                exclude={data.content[0].article.id}
                limit={5}
                horizontal={true}
                language={language}
              />
            </div>
          </div>
          <div class="item-recom --mt">
            <div class="item-header">
              <h3>Recommended Articles</h3>
            </div>
            <div class="item-container">
              <ThumbnailNews
                title={strings.recommendedforyou}
                category={data.content[0].category.slug}
                exclude={data.content[0].article.id}
                limit={5}
                language={language}
                horizontal={true}
              />
            </div>
          </div>
        </div>
      </div>
      <SiteFooter />
    </div>
  );
};

export default SingleMovieReview;
