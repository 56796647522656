import React, { Component } from "react";
import { Query } from "react-apollo";
import MetaTags from "react-meta-tags";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import { Loader } from "../Loader";
import SiteHeader from "../App/Header";
import SiteFooter from "../App/Footer";
import ThumbnailNews from "../Home/ThumbnailNews";
import { string } from "prop-types";
import strings from "../Locale";
import { FBIcon } from "./icons";
import { TWIcon } from "./icons";
import Image from "react-graceful-image";
import AdComponent from "../Ads";

const GET_ARTICLE = gql`
  query GetArticle($slug: String, $language: String) {
    getArticles(slug: $slug,language: $language ) {
      article {
        id
        slug
        modifiedAt
      }
      title
      description
      featuredImage
      metaDescription
      category {
        id
        slug
        name
      }

      subcategory {
        name
        slug
      }

      articlekeywordsSet {
        keywords {
          keyword
        }
      }
    }
  }
`;
var language, slug;


class ArticleDetails extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://cdn.embedly.com/widgets/platform.js";
    script.async = true;
    document.body.appendChild(script);
  }
  render() {

    language = window.location.pathname.split('/')[1];
    return (
      <Query query={GET_ARTICLE} variables={{ slug: slug, language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          return <ArticleContent content={data.getArticles[0]} />;
        }}
      </Query>
    );
  }
}

export const ArticleContent = (data) => {
  var keywordsArr = [];
  for (let i = 0; i < data.content.articlekeywordsSet.length; i++) {
    keywordsArr.push(data.content.articlekeywordsSet[i].keywords.keyword);
  }

  return (
    <>
      <MetaTags>
        <title>{data.content.title} | TotalTelugu</title>
        <meta name="description" content={data.content.metaDescription} />
        <meta property="og:type" content="article" />

        <meta property="og:title" content={data.content.title} />

        <meta
          property="og:description"
          content={data.content.metaDescription}
        />

        <meta property="og:image" content={data.content.featuredImage} />

        <meta property="og:url" content={window.location.href} />

        <meta property="og:site_name" content="TotalTelugu" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>

      <div className="article-wrapper">
        <div className="item-headers">
          <div className="item-title">
            <h1>{data.content.title}</h1>
          </div>
        </div>
        <div className="item-content">
          <div className="item-misc-info">
            <div className="item-category">
              <span className="item-category">
                <Link to={`/${language}/${data.content.category.slug}`}>
                  {data.content.category.name}{" "}
                </Link>
              </span>
              <span className="item-subcategory">
                <Link to={`/${language}/${data.content.subcategory.slug}`}>
                  {" "}
                  / {data.content.subcategory.name}{" "}
                </Link>
              </span>
            </div>
            <div className="item-date">
              {" "}
              - {moment(data.content.article.modifiedAt).format("dddd, LL")}
            </div>
            <div className="item-share">
              <a
                target="_blank"
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                target="_blank"
                href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
              >
                <i className="fab fa-twitter"></i>
              </a>
              <a
                target="_blank"
                href={`https://api.whatsapp.com/send?text=${data.content.title}  ${window.location.href}`}
              >
                <i className="fab fa-whatsapp"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="item-featured-iamge">
          <Image
            src={data.content.featuredImage}
            alt={data.content.title}
          />
        </div>
        <div className="item-body">
          <p
            dangerouslySetInnerHTML={{
              __html: data.content.description,
            }}
          />
        </div>
        <div className="item-tags">
          <ul className="tags-list">
            {data.content.articlekeywordsSet.map((tags, index) => (
              <li key={index}>
                <a
                  href={`/${language}` + "/tag/" + tags.keywords.keyword}
                  className="tag-link"
                >
                  {tags.keywords.keyword}
                </a>
              </li>
            ))}
          </ul>
        </div>
        <div className="item-extras">
        <div className="ad-unit-wrapper --full">
            <AdComponent  slot="6793421055" format="horizontal" />
          </div>
          <div className="item-related --mt">
            <div className="item-header">
              <h3>Related News</h3>
            </div>
            <div className="item-container">
              <ThumbnailNews
                title={strings.relatedarticles}
                keywords={keywordsArr}
                exclude={data.content.article.id}
                limit={5}
                horizontal={true}
                language={language}
              />
            </div>
          </div>
          <div className="item-recom --mt">
            <div className="item-header">
              <h3>Recommended News</h3>
            </div>
            <div className="item-container">
              <ThumbnailNews
                title={strings.recommendedforyou}
                category={data.content.category.slug}
                exclude={data.content.article.id}
                limit={5}
                language={language}
                horizontal={true}
                
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

class Article extends Component {
  render() {
    slug = this.props.match.params.slug;
    language = this.props.match.params.telugu;

    return (
      <div className="body-wrapper">
        <SiteHeader />
        <ArticleDetails slug={slug} language={language} />
        <SiteFooter />
      </div>
    );
  }
}

export default Article;
