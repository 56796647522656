import React, { Component } from "react";
import TopStories from "./TopStories";
import InFocus from "./InFocus";
import PhotoFeature from "./PhotoFeature";
import HighlightNews from "./HighlightNews";
import ThumbnailNews from "./ThumbnailNews";
import { Loader } from "../Loader";
import SiteHeader from "../App/Header";
import SiteFooter from "../App/Footer";
import LandingPage from "../App/landingPage";
import strings from "../Locale";
import LocalizedStrings from "react-localization";
import Cookies from "js-cookie";
import MetaTags from "react-meta-tags";
import AdComponent from "../Ads";

class Home extends Component {
  constructor() {
    super();
    var splash = window.sessionStorage.getItem("splash");

    if (splash === null) {
      this.state = {
        splash: false,
        language: "English",
      };
    } else {
      this.state = {
        splash: false,
      };
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    // this.setState({language:})
    window.sessionStorage.setItem("splash", false);
    setTimeout(() => {
      this.setState({ splash: false });
    }, 10000);
  }

  handleClick(e) {
    this.setState({ splash: e });
  }

  render() {
    var language;

    if (this.props.match.params.telugu === undefined) {
      language = "english";
    } else {
      language = this.props.match.params.telugu;
    }

    if (this.state.splash) {
      return (
        <div className="body-wrapper">
          <LandingPage onChange={this.handleClick} />
        </div>
      );
    }
    return (
      <div className="body-wrapper">
        <SiteHeader />
        <MetaTags>
          <title>
            Total Telugu News: Online Telugu News | Latest Telugu News |
            Breaking News in Telugu
          </title>
          <meta
            name="description"
            content="Total Telugu news portal Brings the Latest & Breaking news in Telugu, current Telugu news headlines in online on Politics, Film, Sports news in Telugu, Social issues, Current Affairs, Today Telugu news (తెలుగు వార్తలు) Headlines, Updates happening in AndhraPradesh and Telangana, India and around the World."
          />
          <meta
            name="keywords"
            content="News in Telugu, Telugu News, Telugu News Online, Telugu News Today, Latest News in Telugu, Google News in Telugu, AP News in Telugu, Telugu News Headlines, Telugu News LIVE, Total Telugu, Total Telugu News, Breaking News in Telugu, today news in telugu, telugu news updates, today telugu news headlines, తెలుగు వార్తలు"
          />

          <meta
            property="og:title"
            content="Total Telugu News: Online Telugu News | Latest Telugu News | Breaking News in Telugu"
          />

          <meta
            property="og:description"
            content="Total Telugu news portal Brings the Latest & Breaking news in Telugu, current Telugu news headlines in online on Politics, Film, Sports news in Telugu, Social issues, Current Affairs, Today Telugu news (తెలుగు వార్తలు) Headlines, Updates happening in AndhraPradesh and Telangana, India and around the World."
          />

          <meta
            property="og:image"
            content="https://reporterapp-userfiles-mobilehub-740999891.s3.ap-south-1.amazonaws.com/logos/tt.jpg"
          />

          <meta property="og:url" content={window.location.href} />

          <meta property="og:site_name" content="TotalTelugu" />
        </MetaTags>
        <div className="root-sub-container">
          <div className="hp-wrapper">
            <div className="hp-item --m-order-2">
              <TopStories language={language} />
            </div>
            <div className="hp-item in-focus --m-order-1">
              <InFocus language={language} />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Social Media"
                limit={6}
                color="red"
                title={strings.socialmedia}
                language={language}
                
              />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Tv & Movies"
                limit={12}
                color="red"
                title={strings.TvMovies}
                language={language}
              />
            </div>
            <div className="hp-item nest-blocks">
              <ThumbnailNews
                title={strings.photofeature}
                section="Photo Feature"
                color="info"
                limit={2}
                language={language}
              />
               <HighlightNews
                section="Most Popular"
                limit={5}
                color="blue"
                title={strings.gossip}
                language={language}
              />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Photos / Videos"
                limit={3}
                color="red"
                title={strings.photosvideos}
                language={language}
                isFull={true}
              />
            </div>
            <div className="hp-item ad-unit-wrapper --full">
              <AdComponent  slot="3072833740" format="horizontal" />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Sports"
                limit={6}
                color="gold-drop"
                title={strings.sports}
                language={language}
              />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Crime World"
                limit={6}
                color="red"
                title={strings.crime}
                language={language}
              />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Business & Technology"
                limit={6}
                color="mpurple"
                title={strings.businesstechnology}
                language={language}
              />
            </div>

            <div className="hp-item">
              <HighlightNews
                section="Around the World"
                limit={6}
                color="sushi"
                title={strings.aroundtheworld}
                language={language}
              />
            </div>
            <div className="hp-item">
              <HighlightNews
                section="Health & Lifestyle"
                limit={6}
                color="clay"
                title={strings.healthlifestyle}
                language={language}
              />
            </div>

            <div className="hp-item">
              <HighlightNews
                section="Travel & Religious"
                limit={6}
                color="matrix"
                title={strings.travelreligious}
                language={language}
              />
            </div>
             <div className="hp-item ad-unit-wrapper --full">
              <AdComponent  slot="9419584399" format="horizontal" />
            </div>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}
export default Home;
