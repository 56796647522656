import React from "react";

export const Loader = () => (
      <div className="animated-background">
            <div className="background-masker header-top"></div>
            <div className="background-masker header-left"></div>
            <div className="background-masker header-right"></div>
            <div className="background-masker header-bottom"></div>
            <div className="background-masker subheader-left"></div>
            <div className="background-masker subheader-right"></div>
            <div className="background-masker subheader-bottom"></div>
            <div className="background-masker content-top"></div>
            <div className="background-masker content-first-end"></div>
            <div className="background-masker content-second-line"></div>
            <div className="background-masker content-second-end"></div>
            <div className="background-masker content-third-line"></div>
            <div className="background-masker content-third-end"></div>
      </div>
);

export const DataError = () => (
      <div className="panel error-panel">
            <div className="error-wrapper">
                  <div className="error-info">
                        <h3>500</h3>
                        <span>
                              Server error! Please try again after some time
                        </span>
                  </div>
            </div>
      </div>
);
