import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import Image from "react-graceful-image";
import { Loader, DataError } from "../../Loader";
import { slug } from "../../Helpers";
import strings from "../../Locale";
import moment from "moment";

var language;

class ThumbnailNews extends Component {
  render() {
    var GET_ARTICLES = "";
    var exclude = 0;
    var section = "";
    var limit = this.props.limit;

    if (this.props.language === undefined) {
      var isGallery = window.location.href.toString().split("/")[4];
      if (isGallery === "GALLERY") {
        language = "english";
      } else {
        language = this.props.match.params.telugu;
      }
    } else {
      language = this.props.language;
    }

    if (this.props.section) {
      section = slug(this.props.section);
    }
    if (this.props.subcategory) {
    }

    if (this.props.exclude) {
      exclude = this.props.exclude;
    }

    if (this.props.subcategory) {
      GET_ARTICLES = gql`
  query($language:String){
  
  getArticles(language:$language, subcategoryname:"${this.props.subcategory}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit}, excludeArticleId: ${exclude}){
    
    title
    article{
    slug
    articleType
    id
    }
    featuredImage
  }

}
`;
    } else if (this.props.category) {
      GET_ARTICLES = gql`
      query($language:String){
  
  getArticles(language:$language, categorySlug:"${this.props.category}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit},excludeArticleId: ${exclude}){
    
    title
    article{
    slug
    articleType
    id
    }
    featuredImage
  }

}
`;
    } else if (this.props.keywords) {
      GET_ARTICLES = gql`
      query($language:String){
  getArticles(language:$language, keyword:[${'"' +
    this.props.keywords.join('","') +
    '"'}], orderby:"createdDate", sortby:"desc", limit:${
        this.props.limit
      },excludeArticleId: ${exclude}){
    
    title
    article{
    slug
    articleType
    id
    }
    featuredImage
  }

}
`;
    } else {
      GET_ARTICLES = gql`
      query($language:String){
  getArticles(language:$language, section:"${section}", orderby:"createdDate", sortby:"desc", limit:${this.props.limit}){
    
    title
    article{
    slug
    articleType
    id
    }
    featuredImage
  }

}
`;
    }

    return (
      <Query query={GET_ARTICLES} variables={{ language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <DataError />;

          if (data.getArticles.length === 0) {
            return <DataError />;
          }

          if (this.props.horizontal === true) {
            return (
              <THumbnailHorizontal
                mplists={data.getArticles}
                language={language}
                limit={limit}
              />
            );
          } else if (
            this.props.title === strings.andhranews ||
            this.props.title === "Telangana News" ||
            this.props.title === strings.nationalnews ||
            this.props.title === strings.internationalnews
          ) {
            return (
              <div className="panel">
                <div className={"panel-header " + this.props.color}>
                  <h3>{this.props.title}</h3>
                  <Link
                    to={
                      `/${language}` +
                      "/political" +
                      `/${this.props.subcategory}`
                    }
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </Link>
                </div>
                <div className="panel-body">
                  <ThumbnailNewsItem
                    mplists={data.getArticles}
                    language={language}
                  />
                </div>
              </div>
            );
          } else {
            return (
              <div className="panel">
                <div className={"panel-header " + this.props.color}>
                  <h3>{this.props.title} </h3>
                  <Link to={`/${language}` + "/section" + `/${section}`}>
                    <i className="fas fa-ellipsis-v"></i>
                  </Link>
                </div>
                <div className="panel-body">
                  <ThumbnailNewsItem
                    mplists={data.getArticles}
                    language={language}
                  />
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

const ThumbnailNewsItem = ({ mplists, language }) => (
  <>
    {mplists.slice(0, 6).map((article, index) => (
      <div className="thumb-item" key={index}>
        <Link
          to={`/${language}/${article.article.articleType.toLowerCase()}/${
            article.article.slug
          }`}
          className=""
        >
          {/* <span className="DateFormat"> {moment(article.modifiedAt).format( 'll')}</span> */}
          <div className="thumb-wrapper">
            <div className="image-wrapper">
              <Image
                src={article.featuredImage}
                className="content-image-placeholder"
                alt={article.title}
                retry={{ count: -1 }}
              />
            </div>
            <div className="info-wrapper">
              <h3>{article.title}</h3>
            </div>
          </div>
        </Link>
      </div>
    ))}
  </>
);

const THumbnailHorizontal = ({ mplists, language, limit }) => (
  <div className="extra-wrapper">
    {mplists.slice(0, 6).map((article, index) => (
      <Link
        to={`/${language}/${article.article.articleType.toLowerCase()}/${
          article.article.slug
        }`}
        key={index}
      >
        <div className="item-wrapper">
          <div className="item-image">
            <Image src={article.featuredImage} alt={article.title} />
          </div>
          <div className="item-title">
            <h3>{article.title}</h3>
          </div>
        </div>
      </Link>
    ))}
  </div>
);

export default ThumbnailNews;
