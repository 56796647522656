import React, { Component } from "react";
import Logo from "../Logo";
import moment from "moment";
import { Query } from "react-apollo";
import gql from "graphql-tag";

const GET_AD_BANNER = gql`
  query {
    ads(adsLocation: "LANDING-PAGE") {
      url
      isActive
      location
      company
    }
  }
`;

export default class Page404 extends Component {
  constructor() {
    super();

    var timeleft = 10;
    var downloadTimer = setInterval(function () {
      if (document.getElementById("landing-page-timer")) {
        document.getElementById("landing-page-timer").innerText = timeleft;
      }

      timeleft -= 1;
      if (timeleft <= 0) clearInterval(downloadTimer);
    }, 1000);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = () => {
    this.props.onChange(false);
  };

  componentDidMount() {}

  render() {
    return (
      <Query
        asyncMode
        query={GET_AD_BANNER}
        variables={{ adsLocation: this.props.position }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <br />
              </div>
            );
          if (error) {
            setTimeout(() => {
              this.handleClick();

              //window.location.reload();
            }, 1000);
            return (
              <div className="logo-blank">
                {" "}
                <Logo />
              </div>
            );
          }

          return (
            <section className="common-header">
              <div className="row logo-banner">
                <div className="col-md-3">
                  <div className="logo-container">
                    <Logo />
                  </div>

                  <div className="date-container">
                    {moment().format("dddd, LL")}
                  </div>
                </div>
                <div className="col-md-9">
                  <span className="header-ad-holder">
                    <img
                      src="https://tpc.googlesyndication.com/pagead/imgad?id=CICAgKDHt6arSBABGAEyCJft8iQ8GrFc"
                      alt="Advertisement"
                    />
                  </span>
                </div>
              </div>

              <span className="link-to-main">
                <a href="/" onClick={this.handleClick}>
                  click here to go to main page
                </a>
                <p>
                  {" "}
                  or else you will be directed to main page in{" "}
                  <span id="landing-page-timer">10</span> seconds
                </p>
              </span>
              <div className="row nav-row">
                {/* <img src="https://m.timesofindia.com/photo/70752873.jpeg" alt="" /> */}
                <img src={data.ads[0].url} alt="" />
              </div>
            </section>
          );
        }}
      </Query>
    );
  }
}
