import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import Sidebar from "../../Sidebar";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import GalleryComponents from "./GalleryComponents";
import GalleryCategoryRender from "./GalleryCategoryRender";
import GalleryPost from "./single";
import Cookies from "js-cookie";
import Modal, { closeStyle } from "simple-react-modal";
import MetaTags from "react-meta-tags";

class Gallery extends Component {
  render() {
    var language = this.props.match.params.telugu;

    return (
      <div className="body-wrapper">
        <SiteHeader />
        <MetaTags>
          <title>
            Telugu Photo Gallery, Telugu Hot Actress Photos, Images & Photos for
            Telangana News, Movies | Total Telugu
          </title>
          <meta
            name="description"
            content="Get the Telugu Photo Gallery, Telugu Hot Actress Photos, Images & Photos for Telangana News, Latest Movies Photo Gallery & Total Telugu News"
          />

          <meta
            property="og:title"
            content="Telugu Photo Gallery, Telugu Hot Actress Photos, Images & Photos for Telangana News, Movies | Total Telugu "
          />

          <meta
            property="og:description"
            content="Get the Telugu Photo Gallery, Telugu Hot Actress Photos, Images & Photos for Telangana News, Latest Movies Photo Gallery & Total Telugu News"
          />

          <meta property="og:url" content={window.location.href} />

          <meta property="og:site_name" content="TotalTelugu" />
          <link rel="canonical" href={window.location.href} />
        </MetaTags>

        <div className="gallery-wrapper">
          <div className="pod-wrapper">
            <GalleryComponents pod={true} language={language} />
          </div>
          <div className="category-wrapper">
            <GalleryCategoryRender language={language} />
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}

export default Gallery;
