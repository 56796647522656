import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import ArticleList from "../ArticleList";
import { Loader } from "../../Loader";
import strings from "../../Locale";
import ThumbnailNews from "../ThumbnailNews";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";


class SectionNews extends Component {
  render() {
    const language = this.props.match.params.telugu;

    return (
       <div className="body-wrapper">
              <SiteHeader />
              <div className="article-list-wrapper">
                <div className="list-items">
                  <ArticleList
                    section={this.props.match.params.section}
                    limit={999}
                    language={language}
                  />
                </div>
                <div className="item-extras">
                  <div className="item-related --mt">
                    <div className="item-header">
                      <h3>Related News</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.relatedarticles}
                        limit={5}
                        horizontal={true}
                        language={language}
                        section="general"
                      />
                    </div>
                  </div>
                  <div className="item-recom --mt">
                    <div className="item-header">
                      <h3>Recommended News</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.recommendedforyou}
                        section='sports'
                        limit={5}
                        language={language}
                        horizontal={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SiteFooter />
            </div>
    );
  }
}

export default SectionNews;
