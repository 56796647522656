import React, { Component } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { GallerySubOne } from "./GalleryComponents";
import { Link } from "react-router-dom";
import GalleryPost from "./single";
import { Loader } from "../../Loader";

const GET_GALLERY_CATEGORIES = gql`
  query Categories($isGallery: Boolean!) {
    getCategories(isGallery: $isGallery) {
      name
      id
      slug
      isGallery
    }
  }
`;

class GalleryCategoryRender extends Component {
  render() {
    var language = this.props.language;

    return (
      <Query query={GET_GALLERY_CATEGORIES} variables={{ isGallery: true }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          return (
            <div className="single-category-wrapper">
              {data.getCategories.map((category, index) => (
                <div className="category-item" key={index}>
                  <a href={`/${language}` + "/category/" + category.slug}>
                    <span className="category-header">
                      {category.name}
                    </span>
                  </a>

                  <GalleryComponentsCategoryList
                    key={index}
                    data={category}
                    language={language}
                  />
                </div>
              ))}
            </div>
          );
        }}
      </Query>
    );
  }
}

class GalleryComponentsCategoryList extends Component {
  render() {
    var GET_GALLERY_CATEGORY_ITEMS = gql`
{
  
  getArticles( language:"english", excludePickOfTheDay:true, subcategoryname:"${this.props.data.name}", orderby:"createdDate", sortby:"desc",limit:3,articleType: "gallery"){
    
    title
    alternativeTitle
    article{
    id
    slug
    articleType
    }
    featuredImage
    
  }

}
`;

    return (
      <Query query={GET_GALLERY_CATEGORY_ITEMS}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          return (
            <div className="single-wrapper">
              {data.getArticles.map((article, index) => (
                
                <div className="item-container" key={index}>
                  <Link
                    to={`/${this.props.language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
                  >
                    <GallerySubOne
                      title={
                        this.props.language == "telugu"
                          ? article.alternativeTitle
                            ? article.alternativeTitle
                            : article.title
                          : article.title
                      }
                      featuredImage={article.featuredImage}
                      id={article.article.id}
                      description={article.editordata}
                    />
                  </Link>
                </div>
              ))}
            </div>
          );
        }}
      </Query>
    );
  }
}

export default GalleryCategoryRender;
