import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Loader } from "../../Loader";
import Image from "react-graceful-image";
import Pagination from "../../Helpers/pagination";
import moment from "moment";

var limit = 99999999;

var articleList = [];
var totalRecords;
var isGallery = false;
var language;
class ArticleList extends Component {
 render() {
  language = this.props.language;

  var GET_ARTICLES = "";
  var exclude = 0;

  if (this.props.gallery) {
   isGallery = true;
  }

  if (this.props.exclude) {
   exclude = this.props.exclude;
  }

  if (this.props.limit) {
   limit = this.props.limit;
  }

  if (this.props.subcategory) {
   GET_ARTICLES = gql `
query($language:String){
  
  getArticles(language:$language, subcategoryname:"${this.props.subcategory}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit}, excludeArticleId: ${exclude}){
    
    title
    article{
    id
    slug
    modifiedAt
    articleType
    }
    featuredImage
    description
  }

}
`;
  } else if (this.props.category) {
   if (isGallery) {
    GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, categorySlug:"${this.props.category}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit},excludeArticleId: ${exclude}, articleType: "gallery"){
    
    title
    article{
    id
    slug
    modifiedAt
    articleType
    }
    featuredImage
    description
  }

}
`;
   } else {
    GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, categorySlug:"${this.props.category}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit},excludeArticleId: ${exclude}){
    
    title
    article{
    id
    slug
    modifiedAt
    articleType
    }
    featuredImage
    description
  }

}
`;
   }
  } else if (this.props.keywords) {
   GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, keyword:[${'"' +
    this.props.keywords.join('","') +
    '"'}], orderby:"createdDate", sortby:"desc", limit:${
        this.props.limit
      },excludeArticleId: ${exclude}){
    
    title
    article{
    id
    modifiedAt
    slug
    articleType
    }
    featuredImage
    description
  }

}
`;
  } else if (this.props.tag) {
   GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, keyword:"${this.props.tag}", orderby:"createdDate", sortby:"desc", limit:${this.props.limit}){
    
    title
    article{
    id
    slug
    modifiedAt
    articleType
    }
    featuredImage
    description
  }

}
`;
  } else if (this.props.search) {
   GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, searchKeyword:"${this.props.search}", orderby:"createdDate", sortby:"desc"){
    
    title
    article{
    id
    slug
    modifiedAt
    articleType
    }
    featuredImage

    description
  }

}
`;
  } else {
   GET_ARTICLES = gql `
      query($language:String){
  
  getArticles(language:$language, section:"${this.props.section}", orderby:"createdDate", sortby:"desc", limit:${this.props.limit}){
    
    title
    article{
    id
    slug
    
    modifiedAt
    articleType
    }
    featuredImage
   
    description
  }

}
`;
  }

  return (
   <Query query={GET_ARTICLES} variables={{ language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          if (data.getArticles.length === 0 && this.props.search) {
            return (
              <div className="no-data">
                We could not find any results for {this.props.search}
              </div>
            );
          }

          if (data.getArticles.length === 0) {
            return (
              <div className="no-data">
                No data to render in {this.props.section} section
              </div>
            );
          }
          totalRecords = data.getArticles.length;

          return <ArticleListItem articlelist={data.getArticles} />;
        }}
      </Query>
  );
 }
}

class ArticleListItem extends Component {
 constructor(props) {
  super(props);

  this.state = {
   currentData: (articleList = this.props.articlelist),
   article: "",
  };
 }

 componentDidMount() {
  this.setState({ currentData: articleList });
 }

 onPageChange = (data) => {
  const { currentPage, totalPages, pageLimit } = data;

  const offset = (currentPage - 1) * pageLimit;

  const currentList = articleList.slice(offset, offset + pageLimit);

  this.setState({ currentData: currentList });
 };

 render() {
  var currentData = this.state;

  return ( <
   > {
    currentData.currentData.map((article, index) => (
     <div className="list-item" key={index}>
            <Link
              to={`/${language}/${article.article.articleType
                .toLowerCase()
                .toLowerCase()}/${article.article.slug}`}
            >
              <div className="list-image">
                <Image
                  src={article.featuredImage}
                  className="content-image-placeholder"
                  alt={article.title}
                  retry={{ count: -1 }}
                />
              </div>
              <div className="list-info">
                <div className="list-title"><h3>{article.title}</h3></div>
                <div className="list-date">
                  Published on :{" "}
                  {moment(article.article.modifiedAt).format("lll")}
                </div>
                <div
                  className="list-description"
                  dangerouslySetInnerHTML={{
                    __html: article.description
                      .replace(/(.{150})..+/, "$1…")
                      .replace(/<[^>]*>?/gm, ""),
                  }}
                />
              </div>
            </Link>
          </div>
    ))
   }
   <Pagination
          totalRecords={totalRecords}
          pageLimit={6}
          pageNeighbours={1}
          onPageChanged={this.onPageChange}
        /> <
   />
  );
 }
}

export default ArticleList;