import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../Logo";
import moment from "moment";
import { string } from "prop-types";
import strings from "../../Locale";

var language;

if ((language == "") | (language == undefined)) {
  language = "english";
}

class SiteFooter extends Component {
  render() {
    return (
      <footer>
        <div className="footer-row-main">
          <ul className="footer-nav-links">
            <li>
              <Link to={`/${language}` + "/about-us"}>{strings.aboutus}</Link>
            </li>
            <li>
              <Link to={`/${language}` + "/privacy-policy"}>
                {strings.privacy}
              </Link>
            </li>
            <li>
              <Link to={`/${language}` + "/disclaimer"}>
                {strings.disclaimer}
              </Link>
            </li>
          </ul>
          <ul className="social-links">
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/TotalTeluguNews"
              >
                <i className="fab fa-facebook"></i>
              </a>
            </li>
            <li>
              <a target="_blank" href="https://twitter.com/TotalTelugu">
                <i className="fab fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-row-sub">
           <span>
              Copyright © 2019-2020, All rights reserved. TotalTelugu.com{" "}
            </span>
        </div>
      </footer>
    );
  }
}

export default SiteFooter;
