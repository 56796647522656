import React from 'react';
import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
 english:{
   topstories:"Top Stories",
   infocus:"In Focus",
   photofeature:"Photo Feature",
   mostPopular :"Most Popular",
   photosvideos:"photos-videos",
   TvMovies: "Tv & Movies",
   businesstechnology:"business & technology",
   aroundtheworld: "around the world",
   astrology:"astrology",
   healthlifestyle:"health & lifestyle",
   travelreligious:"travel & religious",
   aboutus:"About Us",
   privacypolicy:"Privacy Policy",
   disclaimer:"Disclaimer",
   contactus:"Contact Us",
   jointhecommunity:"Join the community",
   Jmsph:"Join our mailing list for exclusive updates and tips",
   relatedarticles:"Related Articles",
   recommendedforyou:"Recommended for you",
   andhranews:"Andhra News",
   ndris:"No data to render in this section",
   breakingnews:"Breaking News",
   movies:"Movies",
   politics:"Politics",
   gallery:"Gallery",
   moviereviews:"Movie Reviews",
   more:"MORE",
   trending:"TRENDING",
   headlines:"HEAD LINES",
   nationalnews:"NATIONAL NEWS",
   internationalnews:"INTERNATIONAL NEWS",
   socialmedia: "Social Media",
   Others:"Others",
   privacy: "Privacy Policy",
   sports: "Sports",
   crime: "Crime",
   viewmore: "View More",
   gossip: "Gossips"

   
 },
 telugu: {
   topstories:"అగ్ర కథనాలు",
   infocus:"దృష్టిలో",
   photofeature:"ఫోటో లక్షణం",
   mostPopular:"అత్యంత ప్రజాదరణ",
   photosvideos:"ఫోటోలు వీడియోలు",
   TvMovies: "టీవీ సినిమాలు",
   businesstechnology :"వ్యాపారం & సాంకేతికత",
   aroundtheworld:"ప్రపంచమంతటా",
   astrology:"జ్యోతిషశాస్త్రం",
   healthlifestyle:"ఆరోగ్యం & జీవన విధానం",
   travelreligious:"మతపరమైన ప్రయాణం",
   aboutus:"మా గురించి",
   privacypolicy:"గోప్యతా విధానం",
   disclaimer:"డిస్క్లైమర్",
   contactus:"మమ్మల్ని సంప్రదించండి",
   jointhecommunity: "సంఘంలో చేరండి",
   Jmsph:"malokalavandi2",
   relatedarticles:"సంబంధిత కథనాలు",
   recommendedforyou:"మీకు సిఫార్సు చేయబడినది",
   andhranews:"ఆంధ్ర వార్తలు",
   ndris:"ikada m ledu",
   breakingnews:"తాజా వార్తలు",
   movies:"సినిమాలు",
   politics:"రాజకీయాలు",
   gallery:"గ్యాలరి",
   moviereviews:"సినిమా సమీక్షలు",
   more:"మరిన్ని వార్తలు",
   trending:"తీరు",
   headlines:"ముఖ్యాంశాలు",
   nationalnews:"జాతీయ వార్తలు",
   internationalnews:"అంతర్జాతీయ వార్తలు",
   socialmedia: "సోషల్ మీడియా",
   Others:"ఇతరులు",
   sports: "క్రీడలు",
   crime: "నేర ప్రపంచం",
   viewmore: "మరిన్ని చూడండి",
   gossip: "గాసిప్స్"
   
   
   
   
   
   
 }
});

export default strings