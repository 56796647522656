import React, { Component } from 'react';

export default class Page404 extends Component {
	
	render(){

	return(

	<div id="notfound">
<div className="notfound">
<div className="notfound-404"></div>
<h1>404</h1>
<h2>Oops! Page Not Found</h2>
<p>Sorry but the page you are looking for does not exist or is temporarily unavailable</p>
<a href="/">Back to homepage</a>
</div>
</div>

	);
	}
}