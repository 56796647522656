import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
} from "mdbreact";
import Image from "react-graceful-image";
import { Loader, DataError } from "../../Loader";
import { slug } from "../../Helpers";
import moment from "moment";

var GET_SECTION_NEWS;

class HighlightNews extends Component {
  render() {

    var w = window.innerWidth;

    var languageDetails = this.props.language;
    var limit = this.props.limit;
    var color = this.props.color;
    var isFull;

    if (this.props.isFull) {
      isFull = this.props.isFull;
    }

    if ( (w <= 1024) && this.props.tabletLimit ) {
      limit = this.props.tabletLimit
    }

    if ( (w > 1024)  ) {
      limit = this.props.limit;
    }


    if (this.props.category) {
      GET_SECTION_NEWS = gql`
          query($language:String){
      
          getArticles(language:$language, categorySlug:"${this.props.category}", orderby:"createdDate", sortby:"desc",limit:${limit}){
        
        title
        article{
        id
        slug
        modifiedAt
        articleType
        }
        featuredImage
        description
      }

    }
    `;
    } else {
      var section = slug(this.props.section);
      GET_SECTION_NEWS = gql`
        query($language:String){
        getArticles(language:$language, orderby:"createdDate", sortby:"desc",limit:${limit}, section:"${section}") {
          title
          article{
          slug
          articleType
          id
          }
          modifiedAt
          featuredImage
          category {
            
              name
            
          }
        }
      }
    `;
    }

    return (
      <Query query={GET_SECTION_NEWS} variables={{ language: languageDetails }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <DataError />;

          if (data.getArticles.length === 0) {
            return (
              <DataError />
            );
          }

          if (isFull) {
            return (
              <div className="panel">
                <div className={"panel-header " + color}>
                  <h3>{this.props.title}</h3>
                  <a
                    href={`/${languageDetails}/${
                      this.props.section ? "section" : "category"
                    }/${this.props.section ? section : this.props.category}`}
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </a>
                </div>
                <div className="panel-body">
                  <div className="inf-container">
                    <HighlightNewsFull
                      articleData={data.getArticles}
                      language={languageDetails}
                    />
                  </div>
                </div>
              </div>
            );
          } else {
            return (
              <div className="panel">
                <div className={"panel-header " + color}>
                  <h3>{this.props.title}</h3>
                  <a
                    href={`/${languageDetails}/${
                      this.props.section ? "section" : "category"
                    }/${this.props.section ? section : this.props.category}`}
                  >
                    <i className="fas fa-ellipsis-v"></i>
                  </a>
                </div>
                <div className="panel-body">
                  <div className="inf-container">
                    <HighlightNewsImageSingle
                      articleData={data.getArticles[0]}
                      language={languageDetails}
                      isFull={isFull}
                    />
                    <HighlightNewsList
                      articleData={data.getArticles}
                      limit={limit}
                      language={languageDetails}
                      isFull={isFull}
                    />
                  </div>
                </div>
              </div>
            );
          }
        }}
      </Query>
    );
  }
}

const HighlightNewsList = ({ articleData, limit, language }) => (
  <ul>
    {articleData.slice(1, limit).map((article, index) => (
      <li key={index}>
        <Link
          to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
        >
          <h4>{article.title}</h4>
        </Link>
      </li>
    ))}
  </ul>
);

class HighlightNewsImageSlider extends Component {
  render() {
    const language = this.props.language;
    return (
      <div className="inf-slider-container">
        <MDBCarousel
          activeItem={1}
          length={1}
          showControls={false}
          showIndicators={false}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            {this.props.articleData.slice(0, 1).map((article, index) => (
              <MDBCarouselItem key={index} itemId={index + 1}>
                <Link
                  key={index}
                  to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
                >
                  <MDBView>
                    <Image
                      src={article.featuredImage}
                      className="content-image-placeholder d-block w-100"
                      alt={article.title}
                      retry={{ count: -1 }}
                    />

                    <div className="slider-caption">
                      <p>{article.title}</p>
                    </div>
                  </MDBView>
                </Link>
              </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </div>
    );
  }
}

class HighlightNewsImageSingle extends Component {
  render() {
    const language = this.props.language;
    const tmpData = this.props.articleData;
    return (
      <div className="inf-wrapper">
        <Link
          to={`/${language}/${tmpData.article.articleType.toLowerCase()}/${tmpData.article.slug}`}
        >
          <div className="view">
            <Image
              src={tmpData.featuredImage}
              className="content-image-placeholder d-block w-100"
              alt={tmpData.title}
              retry={{ count: -1 }}
            />

            <div className="slider-caption">
              <p>{tmpData.title}</p>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

class HighlightNewsFull extends Component {
  render() {
    const language = this.props.language;
    return (
      <div className="inf-wrapper">
        {this.props.articleData.slice(0, 4).map((article, index) => (
          <div className="inf-item" key={index}>
            <Link
              to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
            >
              <div className="view">
                <Image
                  src={article.featuredImage}
                  className="content-image-placeholder d-block w-100"
                  alt={article.title}
                  retry={{ count: -1 }}
                />
                <div className="slider-caption">
                  <p>{article.title}</p>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    );
  }
}

export default HighlightNews;
