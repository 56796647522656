import React from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import Image from "react-graceful-image";
import { Loader, DataError } from "../../Loader";
import strings from "../../Locale";
import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBContainer,
} from "mdbreact";

var limit = 7;

const GET_INFOCUS_STORIES = gql`
query($language:String){
  

  getArticles(language:$language, orderby:"createdDate", sortby:"desc",limit:${limit},section:"in-focus")
  {
    title
    article{
    id
    slug
    articleType
    }
    featuredImage
    category{

      name
    }
  }

}
`;
var languages;
const InFocus = (language) => (
  <Query
    query={GET_INFOCUS_STORIES}
    variables={{ language: language.language }}
  >
    {({ loading, error, data }) => {
      languages = language.language;
      if (loading) return <Loader />;
      if (error) return <DataError />;
      if (data.getArticles.length === 0) {
        return <DataError />;
      }

      return (
        <div className="panel">
          <div className="panel-header green">
            <h3>{strings.infocus}</h3>
            <a href={`/${languages}` + "/section/in-focus"} className="to-links">
              <i className="fas fa-ellipsis-v"></i>
            </a>
          </div>
          <div className="panel-body">
            <InFocusSlider
              sldierlists={data.getArticles}
              language={languages}
            />
            <InFocusList iflists={data.getArticles} language={languages} />
          </div>
        </div>
      );
    }}
  </Query>
);

const InFocusList = ({ iflists }) => (
  <ul>
    {iflists.slice(3, 7).map((article, index) => (
      <li key={index}>
        <Link
          to={`/${languages}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
          className=""
        >
          <h4>{article.title}</h4>
        </Link>
      </li>
    ))}
  </ul>
);

class InFocusSlider extends React.Component {
  render() {
    const language = this.props.language;
    return (
      <div className="inf-slider-container">
        <MDBCarousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={false}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            {this.props.sldierlists.slice(0, 3).map((article, index) => (
              <MDBCarouselItem key={index} itemId={index + 1}>
                <Link
                  to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
                >
                  <MDBView>
                    <Image
                      src={article.featuredImage}
                      className="content-image-placeholder"
                      alt={article.title}
                      retry={{ count: -1 }}
                    />
                  
                  <div className="slider-caption">
                    <p>{article.title}</p>
                  </div>
                  <div className="carousel-tag">
                    <p className="category-tag">
                      {article.category.name}
                    </p>
                  </div>
                  </MDBView>
                </Link>
              </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </div>
    );
  }
}

export default InFocus;
