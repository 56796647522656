import React, { Component } from "react";
import { Query, graphql } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Loader } from "../../Loader";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import MetaTags from "react-meta-tags";

const mutation = gql`
  mutation contactUs($contactData: ContactFormInput!) {
    contactForm(contact: $contactData) {
      contact {
        isSuccess
      }
    }
  }
`;


class MiscPageAbout extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
      showSpinner: false,
    };
  }

  onChange = (field) => {
    return (event) => {
      let state = {};
      state[field] = event.target.value;
      this.setState(state);
    };
  };

  onSubmit = async (e) => {
    e.preventDefault();

    this.setState({
      showSpinner: true,
    });
    var cData = {};

    if (
      (this.state.name === "") |
      (this.state.email === "") |
      (this.state.subject === "") |
      (this.state.message === "")
    ) {
      let r = document.getElementById("cResp");
      r.innerHTML = "";
      r.innerHTML +=
        '<font style="color: red;">Please fill all the required fields and try again.</font>';
      this.setState({
        showSpinner: false,
      });
      return;
    }

    var re = /\S+@\S+\.\S+/;
    let proceed = re.test(this.state.email);

    if (!proceed) {
      let r = document.getElementById("cResp");
      r.innerHTML = "";
      r.innerHTML +=
        '<font style="color: red;">Please enter a valid email address</font>';
      this.setState({
        showSpinner: false,
      });
      return;
    }

    cData["email"] = this.state.email;
    cData["name"] = this.state.name;
    cData["subject"] = this.state.subject;
    cData["message"] = this.state.message;

    try {
      const response = await this.props.mutate({
        variables: { contactData: cData },
      });

      if (response.data.contactForm.contact.isSuccess) {
        let r = document.getElementById("cResp");
        r.innerHTML = "";
        r.innerHTML +=
          '<font style="color: green;">Your message has been sent successfully. <br/>Our team will get back to you shortly. Thank you</font>';
        this.setState({
          showSpinner: false,
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        document.getElementById("message").value = "";
      }
    } catch (error) {
      this.setState({
        showSpinner: false,
      });
      let r = document.getElementById("cResp");
      r.innerHTML = "";
      r.innerHTML +=
        '<font style="color: red;">Oops! Unknown error occured. Our team has been notified about the issue. <br/>Meanwhile, You can mail us at <font style="color: black;">support@totaltelugu.com</font> to contact us. Thank you</font>';
    }
  };

  render() {
    return (
      <div className="body-wrapper">
        <MetaTags>
          <title>About Us | Total Telugu</title>
          <meta
            name="description"
            content="About Us - Total Telugu news portal Brings the Latest & Breaking news in Telugu, current Telugu news headlines on Politics, Film, Sports news in Telugu, Social issues, Current Affairs, Today Telugu news (తెలుగు వార్తలు) Headlines, Updates happening in AndhraPradesh and Telangana - TotalTelugu.com"
          />

          <meta property="og:title" content="About Us | Total Telugu" />

          <meta
            property="og:description"
            content="About Us - Total Telugu news portal Brings the Latest & Breaking news in Telugu, current Telugu news headlines on Politics, Film, Sports news in Telugu, Social issues, Current Affairs, Today Telugu news (తెలుగు వార్తలు) Headlines, Updates happening in AndhraPradesh and Telangana - TotalTelugu.com"
          />

          <link rel="canonical" href={window.location.href} />
        </MetaTags>
        <SiteHeader />
        <div className="misc-wrapper">
          <div className="content-wrapper">
            <p>
              www.TotalTelugu.com is telugu, english news gossip and
              entertainment website covering politics, movies, tv, sports,
              social media, business, technology, travel, religious, astrology.
              We are a technology platform publishing the content from various
              sources but not limited to user contribution, feedback, apps,
              internet, contests, social media. We are backed by experienced
              media and entertainment intellectuals to provide the best content
              for user entertainment. if needed please contact us at
              contact@totaltelugu.com
            </p>
          </div>
          <div className="form-wrapper">
            <form action="#">
              <label htmlFor="name">
                Name <span className="required-label">*</span>
              </label>
              <input
                type="text"
                id="name"
                name="fullname"
                placeholder="Name"
                onChange={this.onChange("name")}
                value={this.state.name}
                required
              />

              <label htmlFor="email">
                Email <span className="required-label">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                onChange={this.onChange("email")}
                value={this.state.email}
              />

              <label htmlFor="subject">
                Subject <span className="required-label">*</span>
              </label>
              <input
                type="text"
                id="subject"
                name="subject"
                placeholder="Subject"
                onChange={this.onChange("subject")}
                value={this.state.subject}
              />

              <label htmlFor="message">
                Message <span className="required-label">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Message"
                height="200"
                onChange={this.onChange("message")}
              >
                {this.state.message}
              </textarea>

              <button
                onClick={this.onSubmit}
                disabled={this.state.showSpinner}
                className="btn btn-outline-info btn-lg btn-block user-form-btn cForm"
              >
                Submit
              </button>
            </form>
            <span id="cResp" className="response-message cForm"></span>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}

export default MiscPageAbout = graphql(mutation)(MiscPageAbout);
