import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import {
	MDBCarousel,
	MDBCarouselCaption,
	MDBCarouselInner,
	MDBCarouselItem,
	MDBView,
	MDBContainer,
} from "mdbreact";
import Image from "react-graceful-image";
import { Loader } from "../../../Loader";

var limit = 9;

const GET_INFOCUS_STORIES = gql`
	query($language: String, $categorySlug: String, $section: String) {
		getArticles(
			language: $language
			categorySlug: $categorySlug
			section: $section
			orderby: "createdDate"
			sortby: "desc"
			limit: 12
		) {
			title
			article {
				id
				slug
				articleType
			}
			featuredImage
		}
	}
`;

class HeadLines extends Component {
	render() {
		var language = this.props.language;

		return (
			<Query
				query={GET_INFOCUS_STORIES}
				variables={{
					language: language,
					categorySlug: "political",
					section: "in-focus",
				}}
			>
				{({ loading, error, data }) => {
					if (loading) return <Loader />;
					if (error) return <div>Error Loading Data</div>;
					if (data.getArticles.length === 0) {
						return (
							<div className="no-data">
								No data to render in this section
							</div>
						);
					}

					return (
						<div className="panel panel-default">
							<div className={"panel-header " + this.props.color}>
								{this.props.title}
								<a
									href={
										`/${language}` +
										"/section/political-headlines"
									}
								>
									<i className="fas fa-ellipsis-v"></i>
								</a>
								{/* <i className="fas fa-ellipsis-v"></i> */}
							</div>
							<div className="panel-body">
								<div className="head-row">
									<div className="head-slider">
										<HeadLinesSlider
											sldierlists={data.getArticles}
											language={language}
										/>
									</div>
									<div className="head-list">
										<HeadLinesList
											hllists={data.getArticles}
											language={language}
										/>
									</div>
								</div>
							</div>
						</div>
					);
				}}
			</Query>
		);
	}
}

const HeadLinesList = ({ hllists, language }) => (
	<ul>
		{hllists.slice(5, 12).map((article, index) => (
			<li key={index}>
				<Link
					to={`/${language}/${article.article.articleType.toLowerCase()}/${
						article.article.slug
					}`}
					className=""
				>
					<h3>{article.title}</h3>
				</Link>
			</li>
		))}
	</ul>
);

class HeadLinesSlider extends React.Component {
	render() {
		const language = this.props.language;
		return (
			<div className="head-slider-wrapper">
				<MDBCarousel
					activeItem={1}
					length={5}
					showControls={true}
					showIndicators={false}
					className="z-depth-1"
				>
					<MDBCarouselInner>
						{this.props.sldierlists
							.slice(0, 5)
							.map((article, index) => (
								<MDBCarouselItem key={index} itemId={index + 1}>
									<Link
										to={`/${language}/${article.article.articleType.toLowerCase()}/${
											article.article.slug
										}`}
									>
										<MDBView>
											<Image
												src={article.featuredImage}
												alt={index}
											/>

											<div className="slider-caption">
												<h3>{article.title}</h3>
											</div>
										</MDBView>
									</Link>
								</MDBCarouselItem>
							))}
					</MDBCarouselInner>
				</MDBCarousel>
			</div>
		);
	}
}

export default HeadLines;
