import React, { Component } from "react";
import { Link } from "react-router-dom";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import MovieReviewComponents from "./movieReviewComponents";
import strings from "../../Locale";
import HighlightNews from "../HighlightNews";
import ThumbnailNews from "../ThumbnailNews";
import MetaTags from "react-meta-tags";

class MovieNewsLanding extends Component {
  render() {
    const language = this.props.match.params.telugu;

    return (
      <div className="body-wrapper review">
        <SiteHeader />
        <MetaTags>
          <title>
            Movies Reviews - Latest Movie Reviews, Tollywood News, Gossips,
            Telugu Movie News - Total Telugu
          </title>
          <meta
            name="description"
            content="Telugu Movie Reviews, Bollywood Movie Reviews - Get the Latest Movie Reviews, Tollywood News, Gossips, Telugu Movie News @ Total Telugu"
          />

          <meta
            property="og:title"
            content="Movies Reviews - Latest Movie Reviews, Tollywood News, Gossips, Telugu Movie News - Total Telugu"
          />

          <meta
            property="og:description"
            content="Telugu Movie Reviews, Bollywood Movie Reviews - Get the Latest Movie Reviews, Tollywood News, Gossips, Telugu Movie News @ Total Telugu"
          />

          <meta property="og:url" content={window.location.href} />

          <meta property="og:site_name" content="TotalTelugu" />
          <link rel="canonical" href={window.location.href} />
        </MetaTags>
        <div className="review-wrapper">
          <div className="highlight-wrapper">
            <div className="item-main">
              <MovieReviewComponents
                language={language}
                highlight={true}
                min={0}
                max={1}
              />
            </div>
            <div className="item-sub">
              <MovieReviewComponents
                language={language}
                sub={true}
                min={1}
                max={3}
              />
            </div>
          </div>
          <div className="multi-wrapper">
            <div className="wrapper-item">
              <MovieReviewComponents
                language={language}
                thumb={true}
                min={4}
                max={7}
              />
            </div>
            <div className="wrapper-item  --mid">
              <MovieReviewComponents
                language={language}
                thumb={true}
                min={8}
                max={12}
              />
            </div>
            <div className="wrapper-item">
              <MovieReviewComponents
                language={language}
                thumb={true}
                min={13}
                max={16}
              />
            </div>
          </div>
          <div className="more-btn">
            <Link to={`/${language}/category/movies`} className="nav-link">
              {strings.viewmore}
            </Link>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}
export default MovieNewsLanding;
