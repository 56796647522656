import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Loader } from "../../Loader";
import { slug } from "../../Helpers";
import { Link } from "react-router-dom";
import Image from "react-graceful-image";

export default class GalleryComponents extends Component {
  render() {
    var language = this.props.language;
    var GET_GALLERY_ITEM = gql`
      {
        getArticles(
          section: "pick-of-the-day"
          orderby: "createdDate"
          sortby: "asc"
          limit: 7
        ) {
          title
          alternativeTitle
          article {
            id
            slug
            articleType
          }
          description
          featuredImage
        }
      }
    `;

    return (
      <Query query={GET_GALLERY_ITEM}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;
          return (
            <>
              {data.getArticles.slice(0, 7).map((article, index) => (
                <PicOfTheDay data={article} key={index} lang={language} />
              ))}
            </>
          );
        }}
      </Query>
    );
  }
}

export const PicOfTheDay = (data) => {
  return (
    <div className="pod-item">
      <Link
        to={`/${data.lang}/${data.data.article.articleType.toLowerCase()}/${
          data.data.article.slug
        }`}
      >
        <div className="pod-content">
          <Image src={data.data.featuredImage} alt={data.data.title} />
          <div className="pod-title">
            <h4>{data.lang == "telugu"
              ? data.data.alternativeTitle
                ? data.data.alternativeTitle
                : data.data.title
              : data.data.title}
              </h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

export const GalleryPostSingle = (data) => {
  return (
    <Link
      to={`/${data.lang}/${data.data.article.articleType.toLowerCase()}/${
        data.data.article.slug
      }`}
    >
      <div className="gp-wrapper">
        <div className="gp-item">
          <div className="gp-image">
            <Image src={data.data.featuredImage} alt={data.data.title} />
          </div>
          <div className="gp-title">
            <span>{data.data.title}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export class GallerySubOne extends Component {
  render() {
    return (
      <div className="single-item-wrapper">
        <div className="item-image">
          <Image src={this.props.featuredImage} alt={this.props.title} />
        </div>
        <div className="item-title">
              <h4 title={this.props.title}>{this.props.title.replace(
                  /(.{25})..+/,
                  "$1…"
                )}</h4>
        </div>
      </div>
    );
  }
}

