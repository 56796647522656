import React, { Component } from "react";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import MetaTags from "react-meta-tags";

class MiscPagePrivacy extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="body-wrapper">
        <MetaTags>
          <title>Privacy Policy | Total Telugu</title>
          <meta
            name="description"
            content="Privacy Policy - Total Telugu.com"
          />

          <meta property="og:title" content="Privacy Policy | Total Telugu" />

          <meta
            property="og:description"
            content="Privacy Policy - Total Telugu.com"
          />

          <link rel="canonical" href={window.location.href} />
        </MetaTags>
        <SiteHeader />
        <div className="misc-wrapper">
          <div className="content-wrapper --full">
            <p>
              www.TotalTelugu.com owned by Techyz LLC., You can browse/view our
              website without logging in and without providing any personal
              information. We collect information from you when you register on
              our site/ app or subscribe to www.TotalTelugu.com
            </p>

            <p>
              When registering, the personal information voluntarily provided by
              you will be collected, stored to create and maintain your account.
              This may include but not limited to First name, Last name, Phone
              number, email, address etc. We do not sell, trade, or otherwise
              transfer to outside parties your personally identifiable
              information. This does not include trusted third parties who
              assist us in operating our website, conducting our business, or
              servicing you, so long as those parties agree to keep this
              information confidential. We may also release your information
              when we believe release is appropriate to comply with the law,
              enforce our site policies, or protect ours or others rights,
              property, or safety. However, non-personally identifiable visitor
              information may be provided to other parties for marketing,
              advertising, or other uses.
            </p>
            <p>
              www.TotalTelugu.com may use third-party advertising companies to
              display ads when you visit our Web site. These companies may use
              anonymous information about your visits to this and other web
              sites to display advertisements on this site that may be of
              interest to you. In the course of serving advertisements to this
              site, these third-party advertisers may place or recognize a
              unique “cookie” on your browser. Google, as a third-party vendor,
              also uses cookies to serve ads on our site. Users may opt out of
              the use of the cookie by visiting the Google ad and content
              network privacy policy. We have security measures in place to
              protect the loss, misuse, and alteration of the information
              provided by us. We make every effort to maintain the quality of
              service and prevent misuse. To report any complaint or violation
              of breach if privacy, information or content theft please send us
              email at contact@totaltelugu.com clearly mentioning the
              article/issue in subject if the email. We reserve the right to
              change or update this policy at any time. Such changes shall be
              posted to the user and other places we deem appropriate.
            </p>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}

export default MiscPagePrivacy;
