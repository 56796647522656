import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import ArticleList from "../ArticleList";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import ThumbnailNews from "../ThumbnailNews";

class SearchArticles extends Component {
  render() {
    var language = this.props.match.params.telugu;

    return (
      <div className="body-wrapper">
              <SiteHeader />
              <div className="article-list-wrapper">
                <div className="list-items">
                  <ArticleList
                    search={decodeURIComponent(this.props.match.params.search)}
                    language={language}
                  />
                </div>
              </div>
              <SiteFooter />
            </div>
    );
  }
}

export default SearchArticles;
