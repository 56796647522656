import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Page404 from "./Page404";
import Home from "../Home";
import PoliticalNews from "../Home/Political";
import SearchArticles from "../Home/Search";
import MiscPagePrivacy from "../Home/Misc/privacy";
import MiscPageDisclaimer from "../Home/Misc/disclaimer";
import MiscPageAbout from "../Home/Misc/about";
import Article from "../Article";
import SingleMovieReview from "../Home/MovieReviews/single";
import MovieNewsLanding from "../Home/MovieReviews";
import Gallery from "../Home/Gallery";
import GalleryPost from "../Home/Gallery/single";
import CategoryNews from "../Home/Category";
import SubCategoryNews from "../Home/SubCategory";
import SectionNews from "../Home/Section";
import TagNews from "../Home/TagNews";
import "../assets/src/sass/main.scss";


if (!window.localStorage.getItem("language")) {
  window.localStorage.setItem("language", "en");
}

const checkAuth = () => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("token");
  if (!token || !refreshToken || token === "undefined") {
    return false;
  }

  try {
    // { exp: 12903819203 }
    // const { exp } = decode(refreshToken);
    // if (exp < new Date().getTime() / 1000) {
    //   return false;
    // }
  } catch (e) {
    return false;
  }

  return true;
};

const AuthRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/login" }} />
      )
    }
  />
);

const LoginAuth = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      checkAuth() ? (
        <Redirect to={{ pathname: "/account/dashboard" }} />
      ) : (
        <Component {...props} />
      )
    }
  />
);

class Logout extends React.Component {
  render() {
    window.localStorage.clear();
    window.location.href = "/account/login";

    return <p></p>;
  }
}

class App extends Component {
  constructor() {
    super();

    this.state = {
      lang: window.localStorage.getItem("language"),
    };
  }

  componentDidMount() {}

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/:telugu" component={Home} />
          <Route exact path="/:english" component={Home} />

          <Route exact path="/english" component={Home} />
          <Route exact path="/" component={Home} language={"english"} />

          <Route path="/:telugu/politics" component={PoliticalNews} />
          <Route exact path="/:telugu/article/:slug" component={Article} />
          <Route
            exact
            path="/:telugu/movie-review/:slug"
            component={SingleMovieReview}
          />
          <Route
            exact
            path="/:telugu/review/:slug"
            component={SingleMovieReview}
          />
          <Route
            exact
            path="/:telugu/movie-reviews"
            component={MovieNewsLanding}
          />
          <Route path="/:telugu/category/:cname" component={CategoryNews} />
          <Route path="/:telugu/section/:section" component={SectionNews} />
          <Route
            path="/:telugu/political/:subcategory"
            component={SubCategoryNews}
          />
          <Route path="/:telugu/tag/:tag" component={TagNews} />
          <Route
            exact
            path="/:telugu/privacy-policy"
            component={MiscPagePrivacy}
          />
          <Route
            exact
            path="/:telugu/disclaimer"
            component={MiscPageDisclaimer}
          />
          <Route exact path="/:telugu/about-us" component={MiscPageAbout} />
          <Route exact path="/:telugu/gallery" component={Gallery} />
          <Route exact path="/:telugu/gallery/:slug" component={GalleryPost} />
          <Route path="/:telugu/search/:search" component={SearchArticles} />
          <Route component={Page404} />
        </Switch>
      </Router>
    );
  }
}

export default App;
