import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import Image from "react-graceful-image";
import { Loader } from "../../Loader";
import { slug } from "../../Helpers";

var language;
class Trending extends Component {
  render() {
    var GET_ARTICLES = "";
    var exclude = 0;
    var section = "";
    language = this.props.language;

    if (this.props.section) {
      section = slug(this.props.section);
    }

    if (this.props.exclude) {
      exclude = this.props.exclude;
    }

    if (this.props.subcategory) {
      GET_ARTICLES = gql`
query($language:String){
  
  getArticles(language:$language, subcategoryname:"${this.props.subcategory}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit}, excludeArticleId: ${exclude}){
    
    articletitle
    article{
    slug
    id
    }
    featuredImage
  }

}
`;
    } else if (this.props.category) {
      GET_ARTICLES = gql`
           query($language:String){
  
  getArticles(language:$language, categorySlug:"${this.props.category}", orderby:"createdDate", sortby:"desc",limit:${this.props.limit},excludeArticleId: ${exclude}){
    
    articletitle
    article{
    slug
    id
    }
    featuredImage
  }

}
`;
    } else if (this.props.keywords) {
      GET_ARTICLES = gql`
           query($language:String){
  
  getArticles(language:$language, keyword:[${'"' +
    this.props.keywords.join('","') +
    '"'}], orderby:"createdDate", sortby:"desc", limit:${
        this.props.limit
      },excludeArticleId: ${exclude}){
    
    articletitle
    article{
    slug
    id
    }
    featuredImage
  }

}
`;
    } else {
      GET_ARTICLES = gql`
       query($language:String){
  
  getArticles(language:$language, section:"${section}", orderby:"createdDate", sortby:"desc", limit:${this.props.limit}){
    
    articletitle
    article{
    slug
    articleType
    id
    }
    featuredImage
  }

}
`;
    }

    return (
      <Query query={GET_ARTICLES} variables={{ language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          if (data.getArticles.length === 0) {
            return (
              <div className="no-data">
                No data to render in {this.props.section} section
              </div>
            );
          }

          return (
            <div className="panel panel-default">
              <div className={"panel-header " + this.props.color}>
                {this.props.title}
                <a href={`/${language}` + "section/trending"}>
                  <i className="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <div className="panel-body">
                <TrendingNewsItem
                  mplists={data.getArticles}
                  language={language}
                />
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const TrendingNewsItem = ({ mplists }) => (
  <div className="thumb-item-row">
    {mplists.slice(0, 6).map((article, index) => (
      <Link
        to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
        key={index}
        className=""
      >
        <div className="mp-item-container d-flex">
          <div className="col-md-12">{article.articletitle}</div>
        </div>
      </Link>
    ))}
  </div>
);

export default Trending;
