import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import ArticleList from "../ArticleList";
import { Loader } from "../../Loader";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import MetaTags from "react-meta-tags";
import Image from "react-graceful-image";
import strings from "../../Locale";
import ThumbnailNews from "../ThumbnailNews";

const GET_CATEGORY_DET = gql`
  query Categories($categorySlug: String!, $isGallery: Boolean!) {
    getCategories(categorySlug: $categorySlug, isGallery: $isGallery) {
      slug
      SEOTitle
      description
      name
    }
  }
`;
class CategoryNews extends Component {
  render() {
    var language = this.props.match.params.telugu;
    const isGallery = this.props.match.params.cname.includes("gallery");
    const slug = this.props.match.params.cname;

    if (isGallery) {
      language = "english";
    }
    return (
      <Query
        asyncMode
        query={GET_CATEGORY_DET}
        variables={{ categorySlug: slug, isGallery: isGallery }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <div>
                <br />
              </div>
            );
          if (error) return <div>Error Loading Data</div>;
          return (
            <div className="body-wrapper">
              <SiteHeader />
              <MetaTags>
                <title>{data.getCategories[0].SEOTitle}</title>
                <meta
                  name="description"
                  content={data.getCategories[0].description}
                />

                <meta
                  property="og:title"
                  content={data.getCategories[0].SEOTitle}
                />

                <meta
                  property="og:description"
                  content={data.getCategories[0].description}
                />

                <meta property="og:url" content={window.location.href} />

                <meta property="og:site_name" content="TotalTelugu" />
                <link rel="canonical" href={window.location.href} />
              </MetaTags>
              <div className="article-list-wrapper">
                <div className="list-items">
                  <ArticleList
                    category={this.props.match.params.cname}
                    limit={999}
                    language={language}
                    gallery={isGallery}
                  />
                </div>
                <div className="item-extras">
                  <div className="item-related --mt">
                    <div className="item-header">
                      <h3>Related News</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.relatedarticles}
                        limit={5}
                        horizontal={true}
                        language={language}
                        section="general"
                      />
                    </div>
                  </div>
                  <div className="item-recom --mt">
                    <div className="item-header">
                      <h3>Recommended News</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.recommendedforyou}
                        category={slug}
                        limit={5}
                        language={language}
                        horizontal={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SiteFooter />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CategoryNews;
