import React, { Component } from "react";
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { Loader } from "../../Loader";
import { slug } from "../../Helpers";
import { Link } from "react-router-dom";
import Image from "react-graceful-image";

var GET_REVIEW_ITEM;

export default class MovieReviewComponents extends Component {
	render() {
		const language = this.props.language;
		console.log(this.props.offset);
		GET_REVIEW_ITEM = gql`
			query($language: String) {
				getArticles(
					language: $language
					sortby: "desc"
					orderby: "modifiedAt"
					articleType: "review"
				) {
					title
					article {
						id
						slug
						articleType
					}
					description
					movieSet {
						releaseDate
						rating
					}
					featuredImage
				}
			}
		`;

		return (
			<Query query={GET_REVIEW_ITEM} variables={{ language: language }}>
				{({ loading, error, data }) => {
					if (loading) return <Loader />;
					if (error) return <div>Error Loading Data</div>;
					if (this.props.highlight) {
						return (
							<>
								{data.getArticles
									.slice(this.props.min, this.props.max)
									.map((article, index) => (
										<MovieReviewComponentHighlight
											data={article}
											key={index}
											lang={language}
										/>
									))}
							</>
						);
					} else if (this.props.sub) {
						return (
							<>
								{data.getArticles
									.slice(this.props.min, this.props.max)
									.map((article, index) => (
										<MovieReviewComponentSub
											data={article}
											key={index}
											lang={language}
										/>
									))}
							</>
						);
					} else {
						return (
							<>
								{data.getArticles
									.slice(this.props.min, this.props.max)
									.map((article, index) => (
										<MovieReviewComponentThumb
											data={article}
											key={index}
											lang={language}
										/>
									))}
							</>
						);
					}
				}}
			</Query>
		);
	}
}

export const MovieReviewComponentHighlight = (data) => {
	var tmp = data.data.description;
	const desc = `${tmp.substring(0, 100)}...`;

	return (
		<Link to={`/${data.lang}/movie-review/${data.data.article.slug}`}>
			<div className="review-item">
				<div className="review-poster">
					<Image
						src={data.data.featuredImage}
						alt={data.data.title}
					/>
				</div>
				<div className="review-info">
					<div className="item-rating-wrapper">
						<span className="item-date">
							{data.data.movieSet[0].releaseDate}
						</span>
						<span className="item-rating">
							{data.data.movieSet[0].rating}
						</span>
					</div>
					<div className="item-title">
						<h4>{data.data.title}</h4>
					</div>
					<div className="item-body">
						<p
							dangerouslySetInnerHTML={{
								__html: desc,
							}}
						/>
					</div>
				</div>
			</div>
		</Link>
	);
};

export const MovieReviewComponentSub = (data) => {
	return (
		<Link to={`/${data.lang}/movie-review/${data.data.article.slug}`}>
			<div className="review-item">
				<div className="review-poster">
					<Image
						src={data.data.featuredImage}
						alt={data.data.title}
					/>
				</div>
				<div className="review-info">
					<div className="item-rating-wrapper">
						<span className="item-date">
							{data.data.movieSet[0].releaseDate}
						</span>
						<span className="item-rating">
							{data.data.movieSet[0].rating}
						</span>
					</div>
					<div className="item-title">
						<h4>{data.data.title.replace(
									/(.{20})..+/,
									"$1…"
								)}</h4>
					</div>
					<div className="item-body">
						<p
							dangerouslySetInnerHTML={{
								__html: data.data.description.replace(
									/(.{100})..+/,
									"$1…"
								),
							}}
						/>
					</div>
				</div>
			</div>
		</Link>
	);
};

export const MovieReviewComponentThumb = (data) => {
	return (
		<div className="review-item">
			<Link to={`/${data.lang}/movie-review/${data.data.article.slug}`}>
				<div className="review-poster">
					<Image
						src={data.data.featuredImage}
						alt={data.data.title}
					/>
				</div>
				<div className="review-info">
					<div className="item-info-wrapper">
						<span className="item-title">{data.data.title.replace(
									/(.{20})..+/,
									"$1…"
								)}</span>
						<span className="item-rating">
							{data.data.movieSet[0].rating}
						</span>
					</div>
				</div>
			</Link>
		</div>
	);
};
