import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import Slider from "react-slick";


class BreakingNews extends Component {
  render() {
   

    const GET_ARTICLES = gql`
      query($language: String) {
        getArticles(
          orderby: "createdDate"
          sortby: "desc"
          limit: 6
          section: "top-stories"
          language: $language
        ) {
          title
          article {
            id
            slug
          }
          featuredImage
          description
        }
      }
    `;

    var settings = {
      dots: false,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      speed: 300,
      autoplaySpeed: 4000,
      cssEase: "linear",
      arrows: true,
    };
    const language = this.props.language;
    return (
      <Query query={GET_ARTICLES} variables={{ language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error Loading Data</div>;

          return (
            <div className="slider-container">
              <Slider {...settings}>
                {data.getArticles.map((article, index) => (
                  <div key={index}>
                    <Link
                      to={`/${language}/article/${article.article.slug}`}
                      className="bn-links"
                      title={article.title}
                    >
                      <h2>
                        {" "}
                        {article.title.replace(/(.{30})..+/, "$1…")}
                      </h2>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default BreakingNews;
