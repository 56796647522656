import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import ArticleList from "../ArticleList";
import { Loader } from "../../Loader";
import Sidebar from "../../Sidebar";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";

class SubCategoryNews extends Component {
  render() {
    const language = this.props.params.telugu;

    return (
      <div className="body-wrapper">
        <SiteHeader language={language} />
        <div className="row">
          <div className="col-md-8">
            <ArticleList
              subcategory={this.props.match.params.subcategory}
              limit={999}
              language={language}
            />
          </div>

          <Sidebar language={language} />
        </div>
        <SiteFooter />
      </div>
    );
  }
}

export default SubCategoryNews;
