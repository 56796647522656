import React, { Component } from "react";
import { Query } from "react-apollo";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import gql from "graphql-tag";
import moment from "moment";
import { Loader } from "../../Loader";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import strings from "../../Locale";
import ThumbnailNews from "../../Home/ThumbnailNews";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import { FBIcon } from "../../Article/icons";
import { TWIcon } from "../../Article/icons";
import MetaTags from "react-meta-tags";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import ImageMasonry from "dl-image-masonry";

var slug = "";
const GET_GALLERY = gql`
  query GetArticle($slug: String!) {
    getArticles(slug: $slug) {
      alternativeTitle
      article {
        id
        slug
        modifiedAt
      }
      title
      description
      featuredImage

      category {
        id
        slug
        name
      }

      subcategory {
        name
        slug
      }

      articlekeywordsSet {
        keywords {
          keyword
        }
      }
      attachmentsSet {
        path
        filename
      }
    }
  }
`;
var language;
class GalleryPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImage: 0,
      viewerIsOpen: false,
    };
    this.openLightbox = this.openLightbox.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
  }

  openLightbox = (i) => {
    this.setState({ viewerIsOpen: true, currentImage: i });
  };

  closeLightbox = () => {
    this.setState({ currentImage: 0, viewerIsOpen: false });
  };

  render() {
    var images = [];

    language = this.props.match.params.telugu;

    if (this.props.match && this.props.match.params.slug) {
      slug = this.props.match.params.slug;
    } else {
      slug = this.props.slug;
    }

    return (
      <Query query={GET_GALLERY} variables={{ slug: slug }}>
        {({ loading, error, data }) => {
          if (loading) return <div></div>;
          if (error) return <div>Error Loading Data</div>;
          if (data.getArticles.length === 0) {
            return (
              <div className="no-data">No data to render in this section</div>
            );
          }

          for (let i = 0; i < data.getArticles[0].attachmentsSet.length; i++) {
            if (
              !data.getArticles[0].attachmentsSet[i].isFeaturedImage &&
              data.getArticles[0].attachmentsSet[i].path !== ""
            ) {
              images.push(data.getArticles[0].attachmentsSet[i].path);
            }
          }

          var keywordsArr = [];
          for (
            let i = 0;
            i < data.getArticles[0].articlekeywordsSet.length;
            i++
          ) {
            keywordsArr.push(
              data.getArticles[0].articlekeywordsSet[i].keywords.keyword
            );
          }
          return (
            <div className="body-wrapper">
              <SiteHeader />
              <MetaTags>
                <title>{data.getArticles[0].title} | TotalTelugu</title>
                <meta
                  name="description"
                  content={data.getArticles[0].description}
                />
                <meta property="og:type" content="article" />

                <meta
                  property="og:title"
                  content={data.getArticles[0].title}
                />

                <meta
                  property="og:description"
                  content={data.getArticles[0].description}
                />

                <meta
                  property="og:image"
                  content={data.getArticles[0].featuredImage}
                />

                <meta property="og:url" content={window.location.href} />

                <meta property="og:site_name" content="TotalTelugu" />
                <meta name="twitter:card" content="summary_large_image" />
                <link rel="canonical" href={window.location.href} />
              </MetaTags>
              <div className="article-wrapper">
                <div className="item-headers">
                  <div className="item-title">
                    <h1>
                      {language == "telugu"
                        ? data.getArticles[0].alternativeTitle
                          ? data.getArticles[0].alternativeTitle
                          : data.getArticles[0].title
                        : data.getArticles[0].title}
                    </h1>
                  </div>
                </div>
                <div className="item-content">
                  <div className="item-misc-info">
                    <div className="item-category">
                      <span className="item-category">
                        <Link
                          to={`/${language}/${data.getArticles[0].category.slug}`}
                        >
                          {data.getArticles[0].category.name}{" "}
                        </Link>
                      </span>
                      <span className="item-subcategory">
                        <Link
                          to={`/${language}/${data.getArticles[0].subcategory.slug}`}
                        >
                          {" "}
                          / {
                            data.getArticles[0].subcategory.name
                          }{" "}
                        </Link>
                      </span>
                    </div>
                    <div className="item-date">
                      {" "}
                      -{" "}
                      {moment(data.getArticles[0].article.modifiedAt).format(
                        "dddd, LL"
                      )}
                    </div>
                    <div className="item-share">
                      <a
                        target="_blank"
                        href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                      >
                        <i className="fab fa-facebook"></i>
                      </a>
                      <a
                        target="_blank"
                        href={`https://twitter.com/intent/tweet?url=${window.location.href}`}
                      >
                        <i className="fab fa-twitter"></i>
                      </a>
                      <a
                        target="_blank"
                        href={`https://api.whatsapp.com/send?text=${data.getArticles[0].title}  ${window.location.href}`}
                      >
                        <i className="fab fa-whatsapp"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="item-body">
                  <ImageMasonry
                    imageUrls={images}
                    numCols={3}
                    onImageClick={(event, index) => {
                      this.openLightbox(index);
                    }}
                  ></ImageMasonry>
                  <ModalGateway>
                    {this.state.viewerIsOpen ? (
                      <Modal onClose={this.closeLightbox}>
                        <Carousel
                          currentIndex={this.state.currentImage}
                          views={images.map((x) => ({
                            ...x,
                            source: x,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
                <div className="item-tags">
                  <ul className="tags-list">
                    {data.getArticles[0].articlekeywordsSet.map(
                      (tags, index) => (
                        <li key={index}>
                          <a
                            href={
                              `/${language}` + "/tag/" + tags.keywords.keyword
                            }
                            className="tag-link"
                          >
                            {tags.keywords.keyword}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="item-extras">
                  <div className="item-related --mt">
                    <div className="item-header">
                      <h3>Related Gallery</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.relatedarticles}
                        keywords={keywordsArr}
                        exclude={data.getArticles[0].article.id}
                        limit={5}
                        horizontal={true}
                        language={language}
                      />
                    </div>
                  </div>
                  <div className="item-recom --mt">
                    <div className="item-header">
                      <h3>Recommended Gallery</h3>
                    </div>
                    <div className="item-container">
                      <ThumbnailNews
                        title={strings.recommendedforyou}
                        category={data.getArticles[0].category.slug}
                        exclude={data.getArticles[0].article.id}
                        limit={5}
                        language={language}
                        horizontal={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <SiteFooter />
            </div>
          );
        }}
      </Query>
    );
  }
}

export default GalleryPost;
