import React, { Component } from "react";
import logo1 from  './logo.png';


class Logo extends Component  {

	render() {
		

    	return(

    		
    		<img className="main-logo" src="https://reporterapp-userfiles-mobilehub-740999891.s3.ap-south-1.amazonaws.com/logos/tt.jpg" alt="TotalTelugu Logo"/>
   			
    		);
	}
}

export const DashLogo = () => (
   

    <img src={logo1} alt="Dash Logo"/>
 
)

export default Logo;

