import React, { Component } from "react";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import MetaTags from "react-meta-tags";

class MiscPageDisclaimer extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="body-wrapper">
        <MetaTags>
          <title>Disclaimer | Total Telugu</title>
          <meta name="description" content="Disclaimer - TotalTelugu.com" />

          <meta property="og:title" content="Disclaimer | Total Telugu" />

          <meta
            property="og:description"
            content="Disclaimer - TotalTelugu.com"
          />

          <link rel="canonical" href={window.location.href} />
        </MetaTags>
        <SiteHeader />
        <div className="misc-wrapper">
          <div className="content-wrapper --full">
            <p>
              All the information on this website, www.TotalTelugu.com is
              published in good faith and for general information purpose only.
              www.TotalTelugu.com owned/powered by Techyz LLC., along with its
              owners, associates and employees make no warranties or
              representations. We are not responsible for any errors, omissions
              or representations on any of our pages or links on any of our
              pages. Please verify the accuracy of all information on your own
              before undertaking any reliance. Any action you take upon the
              information you find on this website is strictly at your own risk.
              www.TotalTelugu.com will not be liable for any losses and/or
              damages in connection with the use of our website.
            </p>

            <p>
              www.TotalTelugu.com publishes material submitted by users in the
              form of inputs/feedbacks, content available on Internet or Social
              Media and does not claim ownership or accepts no responsibility
              for the accuracy of such content nor does it make any
              representations by virtue of the contents. If you believe we
              infringed any copyright it clearly not our intent. Please contact
              us at contact@totaltelugu.com and identify the article in the
              subject line and we will verify and remove at the earliest.
            </p>
            <p>
              All information software, products, services and related content
              (Pictures, Videos, Graphics and any media) are provided as
              received without warranty of any kind. www.TotalTelugu.com hereby
              expressly disclaim all or any warranties and conditions about the
              information, software, products, services and related content,
              including all implied warranties and conditions of
              merchantability, fitness for a particular purpose, title and
              non-infringement. We hereby expressly disclaim any implied
              warranties imputed by the laws of any jurisdiction. We consider
              ourselves and intend to be subject to the jurisdiction only of the
              courts of the state of Michigan.
            </p>

            <p>
              From our website, you can/may visit other websites by following
              hyperlinks to such external sites. We have no control over the
              content and nature of those sites. These links to other websites
              do not imply a recommendation for all the content found on these
              sites. Site owners and content may change without notice and may
              occur before we can remove a link which may have gone ‘bad’. These
              sites may have different privacy policies and terms which are
              beyond our control. Please be sure to check the Privacy Policies
              of these sites as well as their “Terms of Service” before engaging
              in any business or uploading any information.
            </p>

            <p>
              If you have any questions or concerns about a published article,
              please send us email at contact@totaltelugu.com clearly mentioning
              the article in subject if the email. We will review your request
              and article will be removed at the earliest in the order received.
              At any time if you are not satisfied with any portion of the
              www.TotalTelugu.com web site, or with any of these terms of use,
              your sole and exclusive remedy is to discontinue using the
              www.TotalTelugu.com web site. By using our website, you hereby
              consent to our disclaimer and agree to its terms.
            </p>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}

export default MiscPageDisclaimer;
