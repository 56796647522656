import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import {
  MDBCarousel,
  MDBCarouselCaption,
  MDBCarouselInner,
  MDBCarouselItem,
  MDBView,
  MDBContainer,
} from "mdbreact";
import { Loader } from "../../Loader";

var lang = window.location.href.toString().split("/")[3];

const GET_PHOTO_FEATURES = gql`
  {
    getArticles(language:"${lang}", orderby:"createdDate", sortby:"desc",limit:13,section:"Photo Feature") {
      title
      article{
      id
      slug
      articleType
      }
      featuredImage
      category {
        
          name
        
      }
    }
  }
`;

const PhotoFeature = () => (
  <div>
    <Query query={GET_PHOTO_FEATURES}>
      {({ loading, error, data }) => {
        if (loading) return <Loader />;
        if (error) return <div>Error Loading Data</div>;

        if (data.GetArticlesBySections.length === 0) {
          return (
            <div className="no-data">No data to render in this section</div>
          );
        }

        return (
          <div className="inf-container">
            <PhotoFeatureSlider sldierlists={data.getArticles} />
            <PhotoFeatureList pflists={data.getArticles} />
          </div>
        );
      }}
    </Query>
  </div>
);

const PhotoFeatureList = ({ pflists }) => (
  <ul>
    {pflists.slice(0, 1).map((article, index) => (
      <li key={article.title}>
        <Link
          to={`/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
          className=""
        >
          {article.title}
        </Link>
      </li>
    ))}
  </ul>
);

class PhotoFeatureSlider extends Component {
  render() {
    return (
      <div className="inf-slider-container">
        <MDBCarousel
          activeItem={1}
          length={1}
          showControls={false}
          showIndicators={false}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            {this.props.sldierlists.slice(1, 2).map((article, index) => (
              <MDBCarouselItem key={index} itemId={index + 1}>
                <Link
                  to={`/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
                >
                  <MDBView>
                    <img
                      className="d-block w-100"
                      src={article.featuredImage}
                      alt={index}
                    />
                  </MDBView>
                  <MDBCarouselCaption>
                    <p>{article.title}</p>
                  </MDBCarouselCaption>
                </Link>
              </MDBCarouselItem>
            ))}
          </MDBCarouselInner>
        </MDBCarousel>
      </div>
    );
  }
}

export default PhotoFeature;
