import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Loader } from "../../Loader";
import Image from "react-graceful-image";

class ColumnNews extends Component {
  render() {
    var subcatagory = this.props.title;
    var language = this.props.language;

    const GET_MOST_POPULAR = gql`
      query($language: String) {
        getArticles(language: $language) {
          title
          article {
            id
            slug
            articleType
          }
          featuredImage
        }
      }
    `;

    return (
      <Query query={GET_MOST_POPULAR} variables={{ language: language }}>
        {({ loading, error, data }) => {
          if (loading) return <Loader />;
          if (error) return <div>Error Loading Data</div>;

          if (data.getArticles.length === 0) {
            return (
              <div className="no-data">No data to render in this section</div>
            );
          }

          return (
            <div className="panel panel-default">
              <div className={"panel-header " + this.props.color}>
                {this.props.title}
                <a
                  href={`/${language}` + "/political/" + this.props.subcategory}
                >
                  <i className="fas fa-ellipsis-v"></i>
                </a>
              </div>
              <div className="panel-body">
                <div className="column-wrapper">
                  <div className="column-image-wrapper">
                    <ColumnNewsItem
                      mplists={data.getArticles}
                      language={language}
                    />
                  </div>
                  <div className="column-list-wrapper">
                    <ColumnNewsList
                      mplists={data.getArticles}
                      language={language}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

const ColumnNewsItem = ({ mplists, language }) => (
  <>
    {mplists.slice(0, 5).map((article, index) => (
      <Link
        to={`/${language}/article/${article.article.slug}`}
        key={index}
        className=""
      >
        <div className="thumb-wrapper">
          <Image
            src={article.featuredImage}
            className="content-image-placeholder"
            alt={article.title}
            retry={{ count: -1 }}
          />
        </div>
        <div className="title-wrapper">
          <h4>{article.title}</h4>
        </div>
      </Link>
    ))}
  </>
);

const ColumnNewsList = ({ mplists, language }) => (
  <ul>
    {mplists.slice(5, 20).map((article, index) => (
      <li key={index}>
        <Link
          to={`/${language}/${article.article.articleType.toLowerCase()}/${
            article.article.slug
          }`}
        >
          <h4>{article.title}</h4>
        </Link>
      </li>
    ))}
  </ul>
);

export default ColumnNews;
