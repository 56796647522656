import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable'; 
import React from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { InMemoryCache } from "apollo-cache-inmemory";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

const GRAPHQL_LINK = process.env.REACT_APP_GRAPHQL_URL;

const httpLink = new HttpLink({
  uri: GRAPHQL_LINK
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
 
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});


const link = ApolloLink.from([errorLink, httpLink]);

const cache = new InMemoryCache();

const client = new ApolloClient({
  link,
  cache
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
);

serviceWorker.unregister();
