import React, { Component } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Loader } from "../Loader";
import AdBanner from "../Home/AdBanners";
import strings from "../Locale";
import ThumbnailNews from "../Home/ThumbnailNews";

const GET_RELATED_NEWS = gql`
  query GetNewsbycategory($categorySlug: String, $language: String) {
    getArticles(language: $language, categorySlug: $categorySlug, limit: 5) {
      articletitle
      article {
        id
        slug
      }
    }
  }
`;

class Sidebar extends Component {
  render() {
    const language = this.props.language;
    const related = this.props.related ? this.props.related : "";
    const recommended = this.props.recommended ? this.props.recommended : "";
    return (
      <div className="col-md-4">
        <Query
          asyncMode
          query={GET_RELATED_NEWS}
          variables={{ categorySlug: related, language: language }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return <Loader />
            if (error) return <div>Error Loading Data</div>;

            return (
              <div className="panel panel-default mt20">
                <div className="panel-header pink">
                  {strings.relatedarticles}
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                <div className="panel-body">
                  <ul>
                    {data.getArticles.map((article, index) => (
                      <li key={index}>
                        <Link
                          to={`/${language}/article/${article.article.slug}`}
                          className=""
                        >
                          {article.articletitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          }}
        </Query>

        <Query
          asyncMode
          query={GET_RELATED_NEWS}
          variables={{ categorySlug: recommended, language: language }}
        >
          {({ loading, error, data }) => {
            if (loading)
              return <Loader />
            if (error) return <div>Error Loading Data</div>;

            return (
              <div className="panel panel-default mt20">
                <div className="panel-header blue">
                  {strings.recommendedforyou}
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </div>
                <div className="panel-body">
                  <ul>
                    {data.getArticles.map((article, index) => (
                      <li key={index}>
                        <Link
                          to={`/${language}/article/${article.article.slug}`}
                          className=""
                        >
                          {article.articletitle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Sidebar;
