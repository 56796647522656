import React from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import gql from "graphql-tag";
import { Loader, DataError } from "../../Loader";
import moment from "moment";
import strings from "../../Locale";

const GET_TOP_STORIES = gql`
  query($language: String) {
    getArticles(
      language: $language
      orderby: "createdDate"
      sortby: "desc"
      limit: 11
      section: "top-stories"
    ) {
      title
      article {
        id
        articleType
        slug
        modifiedAt
      }
    }
  }
`;

const TopStories = (language) => (
  <Query query={GET_TOP_STORIES} variables={language}>
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error) return <DataError />;

      if (data.getArticles.length === 0) {
        return <DataError />;
      }

      return (
        <TopStoriesList
          tplists={data.getArticles}
          language={language.language}
        />
      );
    }}
  </Query>
);

const TopStoriesList = ({ tplists, language }) => (
  <div className="panel">
    <div className="panel-header cyan">
      <h3>{strings.topstories}</h3>
      <a href={`/${language}` + "/section/top-stories"} className="to-links">
        <i className="fas fa-ellipsis-v"></i>
      </a>
    </div>
    <div className="panel-body">
      <ul>
        {tplists.map((article, index) => (
          <li key={index}>
            <Link
              to={`/${language}/${article.article.articleType.toLowerCase()}/${article.article.slug}`}
              className=""
            >
              <h4>{article.title}</h4>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default TopStories;
