import React, { Component } from "react";
import ThumbnailNews from "../ThumbnailNews";
import HeadLines from "./HeadLines";
import ColumnNews from "../ColumnNews";
import HighlightNews from "../HighlightNews";
import Sidebar from "../../Sidebar";
import SiteHeader from "../../App/Header";
import SiteFooter from "../../App/Footer";
import Trending from "../Trending";
import strings from "../../Locale";
import MetaTags from "react-meta-tags";

class PoliticalNews extends Component {
  render() {
    const language = this.props.match.params.telugu;
    return (
      <div className="body-wrapper">
        <SiteHeader />
        <MetaTags>
          <title>
            Politics: Political News in Telugu, Ap Politics News in Telugu,
            తెలుగు రాజకీయ వార్తలు - Total Telugu
          </title>
          <meta
            name="description"
            content="Politics News: Check Political News in Telugu, Ap Politics News in Telugu, తెలుగు రాజకీయ వార్తలు - Total Telugu News"
          />

          <meta
            property="og:title"
            content="Politics: Political News in Telugu, Ap Politics News in Telugu, తెలుగు రాజకీయ వార్తలు - Total Telugu"
          />

          <meta
            property="og:description"
            content="Politics News: Check Political News in Telugu, Ap Politics News in Telugu, తెలుగు రాజకీయ వార్తలు - Total Telugu News"
          />

          <meta property="og:url" content={window.location.href} />

          <meta property="og:site_name" content="TotalTelugu" />
          <link rel="canonical" href={window.location.href} />
        </MetaTags>

        <div className="political-wrapper">
          <div className="main-wrapper">
            <div className="pl-item --full">
              <HeadLines
                title={strings.headlines}
                color="green"
                language={language}
              />
            </div>
            <div className="pl-item --half">
              <ThumbnailNews
                title="Andhra News"
                color="red"
                subcategory="Andhra Pradesh"
                limit={5}
                language={language}
              />
            </div>
            <div className="pl-item --half">
              <ThumbnailNews
                title="Telangana News"
                color="blue"
                subcategory="Telangana"
                limit={5}
                language={language}
              />
            </div>
            <div className="pl-item --full">
              <ColumnNews
                title={strings.nationalnews}
                subcategory="national"
                color="matrix"
                language={language}
              />
            </div>
            <div className="pl-item --full">
              <ColumnNews
                title={strings.internationalnews}
                subcategory="international"
                color="clay"
                language={language}
              />
            </div>
          </div>
          <div className="side-wrapper">
            <div className="sidebar-item">
              <HighlightNews
                section=""
                limit={4}
                color="pink"
                title={strings.trending}
                language={language}
              />
            </div>
            <div className="sidebar-item">
              <HighlightNews
                section="Sports"
                limit={6}
                color="gold-drop"
                title={strings.astrology}
                language={language}
              />
            </div>
            <div className="sidebar-item">
              <ThumbnailNews
                title={strings.recommendedforyou}
                section="Most Popular"
                limit={6}
                color="pink"
                language={language}
              />
            </div>
          </div>
        </div>
        <SiteFooter />
      </div>
    );
  }
}
export default PoliticalNews;
